import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":_vm.options.color,"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"text-body-2 font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pa-4 black--text",domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VCardActions,{staticClass:"pt-3"},[_c(VSpacer),(!_vm.options.noconfirm)?_c(VBtn,{staticClass:"body-2 font-weight-bold",attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Abbrechen")]):_vm._e(),_c(VBtn,{staticClass:"body-2 font-weight-bold",attrs:{"color":"black","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v("Ja, ich bin sicher")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }