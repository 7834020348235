import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{attrs:{"text":"","top":"","center":"","color":_vm.color,"timeout":_vm.timeout,"multi-line":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"text":"","color":_vm.color},on:{"click":function($event){_vm.show = false}}},'v-btn',attrs,false),[_c(VIcon,{attrs:{"small":""}},[_vm._v("close")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VLayout,{attrs:{"align-center":"","pr-4":""}},[_c(VIcon,{staticClass:"pr-3",attrs:{"large":"","color":_vm.color}},[_vm._v(_vm._s(_vm.icon))]),_c(VLayout,{attrs:{"column":""}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.title))])]),_c('div',[_vm._v(_vm._s(_vm.message))])])],1),(_vm.timeout >= 0)?_c(VProgressLinear,{attrs:{"color":_vm.color,"absolute":"","bottom":"","value":Math.floor(100 * (1-(_vm.currentTime / _vm.timeout)))}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }