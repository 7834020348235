import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTextField,{attrs:{"prepend-icon":_vm.icon || 'format_paint',"rules":[v => /^#[0-9A-F]{6,6}$/.test(v) || 'kein gültiger Farbcode'],"label":_vm.label || 'Farbe',"append-outer-icon":_vm.original?'undo':''},on:{"input":val => _vm.$emit('input', val),"click:append-outer":_vm.onAppendOuter},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VMenu,{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({attrs:{"color":_vm.color}},on),[_vm._v("square")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":val => _vm.$emit('input', val)},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})
}
var staticRenderFns = []

export { render, staticRenderFns }