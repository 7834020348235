import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VToolbar,{attrs:{"flat":""}},[_vm._t("left",function(){return [_c(VAppBarNavIcon,{on:{"mouseover":function($event){$event.preventDefault();}}},[_vm._t("icon"),(!!_vm.icon)?_c(VIcon,[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.back)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({on:{"click":function($event){return _vm.$router.go(-1)}}},'v-icon',attrs,false),on),[_vm._v(" arrow_back ")])]}}],null,false,3899368168)},[_vm._v(" Zurück ")]):_vm._e()],2),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))])]}),_c(VSpacer),(_vm.search)?_c('ToolbarSearch',{attrs:{"search":_vm.searchText},on:{"update:search":value => _vm.$emit('update:searchText', value)}}):_vm._e(),(_vm.search && _vm.searchPosition !== 'right')?_c(VSpacer):_vm._e(),_vm._t("right")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }