import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c(VCard,{staticStyle:{"margin":"0px","padding":"0px"},attrs:{"dark":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.userLoaded.cn)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.userLoaded.uid)+" ")]),_c(VDivider),_c(VDivider),_c(VCardText,[_c(VList,[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("night_shelter")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.userLoaded.title))]),_c(VListItemSubtitle,[_vm._v("Zugehörigkeit")])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("home")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.userLoaded.l))]),_c(VListItemSubtitle,[_vm._v("Ort")])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,[_vm._v("mail")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.userLoaded.mail))]),_c(VListItemSubtitle,[_vm._v("E-Mailadresse")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }