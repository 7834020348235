import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectable)?_c(VMenu,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"padding":"0","min-width":"0"},attrs:{"text":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.currentItem && _vm.currentItem.color || ''}},[_vm._v(_vm._s(_vm.currentItem && _vm.currentItem.icon || 'check_box_outline_blank'))])],1)]}}],null,false,3809064280)},[(!_vm.readonly)?_c(VList,{attrs:{"nav":"","dense":""}},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,attrs:{"link":""},on:{"click":function($event){_vm.currentItem=item}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_vm._v(_vm._s(item.text))])],1)}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }